var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BackNav", {
        on: {
          click: function ($event) {
            return _vm.$emit("back")
          },
        },
      }),
      _c("p", { staticClass: "text-lg font-bold text-dark" }, [
        _vm._v("Pay now"),
      ]),
      _c("span", { staticClass: "text-xs md:text-sm text-dark mt-4" }, [
        _vm._v("How much do you want to pay?"),
      ]),
      _c("CurrencyInput", {
        attrs: {
          placeholder: "E.g N2,000",
          width: "w-full",
          validation: _vm.rules.amount,
          errorText:
            "Amount must be greater than N50 and less than N" +
            _vm.singleScheduleData.amountOwed,
        },
        model: {
          value: _vm.payNow.paybackAmount,
          callback: function ($$v) {
            _vm.$set(_vm.payNow, "paybackAmount", $$v)
          },
          expression: "payNow.paybackAmount",
        },
      }),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          _c("span", { staticClass: "text-xs md:text-sm text-dark mt-4" }, [
            _vm._v("Payment method"),
          ]),
          _c("Select", {
            staticClass: "select",
            attrs: {
              placeholder: "How do you want to pay",
              options: _vm.options,
              reduce: function (type) {
                return type.value
              },
            },
            model: {
              value: _vm.payNow.repaymentMethod,
              callback: function ($$v) {
                _vm.$set(_vm.payNow, "repaymentMethod", $$v)
              },
              expression: "payNow.repaymentMethod",
            },
          }),
          _vm.payNow.repaymentMethod === "CARD"
            ? _c(
                "div",
                { staticClass: "mt-10" },
                [
                  _c("CardSelect", {
                    model: {
                      value: _vm.payNow.tokenizedCardId,
                      callback: function ($$v) {
                        _vm.$set(_vm.payNow, "tokenizedCardId", $$v)
                      },
                      expression: "payNow.tokenizedCardId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("Button", {
            staticClass: "mt-16",
            attrs: {
              text: "Complete payment",
              width: "w-full",
              loading: _vm.loading,
              disabled: _vm.disableButton,
            },
            on: { click: _vm.payRepayment },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }